export const formConfig = {
    namespaced: true,
    state: {
      configObj: {},
    },
    getters: {
        configObj: state => () => {
          return state.configObj;
        }
      },
    mutations: {
      setConfigObj(state, configObj) {
        state.configObj = configObj;
      },
    },
  };

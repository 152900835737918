import { createStore } from 'vuex'
import { formConfig } from './modules/formConfig';
export default createStore({
  modules: {
    formConfig
  },
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
})



import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    redirect: "/loginPage",
  },
  {
    path: "/loginPage",
    name:'LoginPage',
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "FormConfig",
        name: "FormConfig",
        component: () => import("../views/FormConfig/FormTable.vue"),
      },
      {
        path: "ProjectAdmin",
        name: "ProjectAdmin",
        component: () => import("../views/ProjectAdmin/ProjectAdmin.vue"),
      },
      {
        path: "MunuConfig",
        name: "MunuConfig",
        component: () => import("../views/MunuConfig/MunuConfig.vue"),
      },
      {
        path: "SystemAuthority",
        name: "SystemAuthority",
        component: () => import("../views/SystemAuthority/SystemAuthority.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;

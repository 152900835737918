<template>
  <div class="home">
    <div class="header">
      <div class="nav">
        <div
          :class="['nav-list', { active: url == '/home/FormConfig' }]"
          @click="toPage('/home/FormConfig')"
        >
          <img
            v-if="url == '/home/FormConfig'"
            src="@/assets/images/menu/jichuku-2-green.svg"
            alt=""
            srcset=""
          />
          <img
            v-else
            src="@/assets/images/menu/jichuku-2.svg"
            alt=""
            srcset=""
          />
          表单配置
        </div>
        <div
          :class="['nav-list', { active: url == '/home/ProjectAdmin' }]"
          @click="toPage('/home/ProjectAdmin')"
        >
          <img
            v-if="url == '/home/ProjectAdmin'"
            src="@/assets/images/menu/jichuku-2-green.svg"
            alt=""
            srcset=""
          />
          <img
            v-else
            src="@/assets/images/menu/jichuku-2.svg"
            alt=""
            srcset=""
          />
          项目管理列表
        </div>
        <div
          v-if="false"
          :class="['nav-list', { active: url == '/home/SystemAuthority' }]"
          @click="toPage('/home/SystemAuthority')"
        >
          <img
            v-if="url == '/home/SystemAuthority'"
            src="@/assets/images/menu/zhibiao-2-green.svg"
            alt=""
            srcset=""
          />
          <img
            v-else
            src="@/assets/images/menu/zhibiao-2.svg"
            alt=""
            srcset=""
          />
          项目权限列表
        </div>
        <div
          v-if="false"
          :class="['nav-list', { active: url == '/home/MunuConfig' }]"
          @click="toPage('/home/MunuConfig')"
        >
          <img
            v-if="url == '/home/MunuConfig'"
            src="@/assets/images/menu/enterprise-2-green.svg"
            alt=""
            srcset=""
          />
          <img
            v-else
            src="@/assets/images/menu/enterprise-2.svg"
            alt=""
            srcset=""
          />
          项目菜单
        </div>
      </div>
    </div>
    <div class="content">
      <div class="logo-box">
        <div class="mune-name">
          <img src="@/assets/images/common/jichuku-2.svg" alt="" srcset="" />
          表单配置
        </div>
        <div class="legout">
          <img src="@/assets/images/common/jichuku-2.svg" alt="" srcset="" />
          <img
            src="@/assets/images/common/user-circle-2-1.svg"
            alt=""
            srcset=""
          />
          QUHUI
        </div>
      </div>
      <div class="section">
        <div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>   

<script>
export default {
  name: "HomeView",
  data() {
    return {};
  },
  mounted() {},
  computed: {
    url() {
      return this.$route.path;
    },
    userName() {
      return JSON.parse(localStorage.getItem("userInfo"))
        ? JSON.parse(localStorage.getItem("userInfo")).user_name
        : "";
    },
  },
  methods: {
    toPage(path) {
      this.url = path;
      this.$router.push(path);
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/loginPage");
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 100%;
  display: flex;
  .header {
    height: 100%;
    width: 240px;
    .nav {
      height: 100%;
      background: linear-gradient(#0ec69a, #00c06c);
      padding-top: 20px;
      box-sizing: border-box;
      .nav-list {
        height: 48px;
        line-height: 48px;
        display: flex;
        align-items: center;
        justify-content: left;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        width: 94%;
        padding-left: 10%;
        box-sizing: border-box;
        border-radius: 0 10px 10px 0;
        > img {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
      }
      .active {
        background: #eff7f6;
        color: #0ec69a;
      }
    }
    > img {
      height: 65%;
      display: none;
    }

    .user-message {
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .content {
    width: calc(100% - 240px);
    background: #eff4f9;
    .logo-box {
      height: 80px;
      // border: 1px solid red;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .mune-name {
        font-size: 22px;
        color: #4a5975;
        display: flex;
        align-items: center;
        > img {
          width: 24px;
          height: 24px;
          margin-right: 7px;
        }
      }
      .legout {
        font-size: 16px;
        color: #4a5975;
        display: flex;
        align-items: center;
        > img {
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
      }
    }
    .section {
      border-radius: 12px;
      height: calc(100% - 80px);
      padding: 24px;
      padding-top: 0;
      box-sizing: border-box;
      > div {
        border-radius: 12px;
        overflow: hidden;
        height: 100%;
      }
    }
  }
}
</style>
